/* eslint-disable object-shorthand */
import './css/styles.scss';
import { Pagination, Mousewheel } from 'swiper/modules';

function InitBlock() {
	// swiper element
	const swiperEl = document.querySelector('.femp-testimonials-block swiper-container');
	if (!swiperEl) return;

	const slides = swiperEl.querySelectorAll('.swiper-slide');
	if (!slides) return;

	// swiper parameters
	const swiperParams = {
		modules: [Pagination, Mousewheel],
		// array with CSS styles
		injectStyles: [
			`

			:host {
				--swiper-pagination-color: #dea023;
				--swiper-pagination-bullet-width: 0.75rem;
				--swiper-pagination-bullet-height: 0.75rem;
			}
			:host .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
			
			bottom: 5px;
		}
		:host .swiper-horizontal {
			padding-bottom: 3rem;
		}
      `,
		],

		slidesPerView: 1,
		spaceBetween: 40,
		loop: false,
		mousewheel: {
			forceToAxis: true,
		},
		// adjust height
		autoHeight: true,
		keyboard: true,
		autoPlay: {
			delay: 5000,
			disableOnInteraction: false,
		},
		pagination: {
			clickable: true,
			type: 'bullets',
			renderBullet: function (index, className) {
				return `<span class="${className}"></span>`;
			},
		},
		navigatiation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		on: {
			init() {},
			paginationUpdate(swiper, paginationEl) {
				// add previous bullet class to all previous bullet
				const bullets = paginationEl.querySelectorAll('.swiper-pagination-bullet');
				if (!bullets.length) return;

				// add class previus-bullet to all previous bullets from active index
				bullets.forEach((bullet, index) => {
					if (swiperEl?.swiper?.activeIndex > index) {
						bullet.classList.add('previous-bullet');
					} else {
						bullet.classList.remove('previous-bullet');
					}
				});
			},
		},
	};

	// now we need to assign all parameters to Swiper element
	Object.assign(swiperEl, swiperParams);

	// and now initialize it
	swiperEl.initialize();
}
document.addEventListener('DOMContentLoaded', function () {
	InitBlock();
});
